var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("div", [
    _c(
      "div",
      {
        staticClass: "thank-you-box",
      },
      [
        _c(
          "b-row",
          [
            _c(
              "b-col",
              {
                staticClass: "text-center",
              },
              [
                _c("img", {
                  attrs: {
                    src: _vm.wuwowThankyouPng,
                    alt: "WUWOW 線上英語教學 感謝您",
                  },
                }),
              ]
            ),
          ],
          1
        ),
        _c(
          "b-row",
          {
            staticClass: "mt-5",
          },
          [
            _c("b-col", [
              _c(
                "span",
                {
                  staticClass: "thank-you-content",
                },
                [
                  _vm._v("謝謝您提供的訊息，我們已經收到您的資料！"),
                  _c("br"),
                  _vm._v("我們會盡快安排專員與您聯絡！"),
                ]
              ),
              _c(
                "p",
                {
                  staticClass: "mt-2 mb-0 thank-you-content",
                },
                [_vm._v("（懶人包限擇一下載）")]
              ),
            ]),
          ],
          1
        ),
        _c(
          "b-row",
          {
            staticClass: "mt-5",
          },
          [
            _c(
              "b-col",
              {
                staticClass: "thank-button-group",
              },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "go-back-home-btn m-bottom-5",
                    class: {
                      disabled:
                        _vm.hasGift !== "arthur" && _vm.hasGift !== "notChoose",
                    },
                    attrs: {
                      variant: "primary",
                      href: "https://wuwow-store.s3.ap-southeast-1.amazonaws.com/pictures/video/2023_jr_king_arthur_legend_the_sword_in_the_stone_voice_book",
                      target: "_blank",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.chooseGift("arthur")
                      },
                    },
                  },
                  [_vm._v("下載 兒童英文繪本有聲書")]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "go-back-home-btn m-bottom-5",
                    class: {
                      disabled:
                        _vm.hasGift !== "cambridge" &&
                        _vm.hasGift !== "notChoose",
                    },
                    attrs: {
                      variant: "primary",
                      href: "https://wuwow-store.s3.ap-southeast-1.amazonaws.com/pictures/Fast+guide/202305_jr_cambridge_english_starters_words_book.pdf",
                      target: "download",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.chooseGift("cambridge")
                      },
                    },
                  },
                  [_vm._v("下載 劍橋兒童英檢單字書")]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "go-back-home-btn",
                    attrs: {
                      variant: "outline-primary",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.backToHome()
                      },
                    },
                  },
                  [_vm._v("返回WUWOW Jr官網")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }